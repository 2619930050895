<template>
  <div class="edit-coach">
    <vs-divider class="md:hidden"/>

    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>
            <custom-profile-image-input :disabled="true" v-model="coach.image"/>

            <div>
              <div class="select-coach">
                <custom-validate-input :label="$t('coaches.labels.user')"
                                       :styles="{width: '100%'}"
                                       :is-empty="true"
                                       :disabled="!checkUserPermissions('coach.updateUser')"
                                       align="right"
                                       v-model="coach.name"/>

                <template v-if="checkUserPermissions('coach.updateUser')">
                  <div class="suggest-modal-button" @click="suggestUserPromptStatus = true">
                    <custom-icon height="22px" width="22px" icon="LIST" color-code="#00bcd4" />
                  </div>

                  <div class="new-suggest-modal-button" @click="newSuggestUserPromptStatus = true">
                    <custom-icon height="22px" width="22px" icon="PLUS" color-code="#28C76F" />
                  </div>
                </template>

                <!-- users List prompt -->
                <vs-prompt
                  class="very-big-prompt p-0"
                  :buttons-hidden="true"
                  title=""
                  :active.sync="suggestUserPromptStatus"
                  @close="suggestUserPromptStatus = true">

                  <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
                    <vs-row>
                      <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                        <div @click="handleClick('selectUsers')">
                          <custom-icon icon="CHECK" color="success"/>
                        </div>
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                        {{ $t('users.select.title')}}
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                        <div @click="suggestUserPromptStatus = false">
                          <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div class="prompt-content py-2 px-3">
                    <template>
                      <keep-alive>
                        <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
                      </keep-alive>
                    </template>
                  </div>

                </vs-prompt>

                <!-- insert new user prompt -->
                <vs-prompt
                  class="prompt p-0"
                  :buttons-hidden="true"
                  title=""
                  :active.sync="newSuggestUserPromptStatus"
                  @close="newSuggestUserPromptStatus = true">

                  <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
                    <vs-row>
                      <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                        <div @click="handleClick('insertUserBTN')">
                          <custom-icon icon="CHECK" color="success"/>
                        </div>
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                        {{ $t('users.user.insert.title') }}
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                        <div @click="newSuggestUserPromptStatus = false">
                          <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div class="prompt-content">
                    <template>
                      <keep-alive>
                        <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
                      </keep-alive>
                    </template>
                  </div>

                </vs-prompt>
              </div>

              <div class="input-field-item character-input">
                <label class="input-field-label">{{ $t('coaches.labels.paymentType') }}</label>
                <div v-for="(item, key) in paymentTypes" class="radio-item">
                  <label :for="`paymentTypeRadio${key}`">{{ item.label }}</label>
                  <input :id="`paymentTypeRadio${key}`" type="radio" :key="key" name="paymentType" :value="item.value"
                         v-model="coach.paymentType.value"/>
                </div>
              </div>

              <custom-price-input :label="$t('coaches.labels.contractPrice')"
                                  :classes="{'w-full': true}"
                                  v-model="coach.contractPrice"/>

              <custom-price-input :label="$t('coaches.labels.reservePrice')"
                                  :classes="{'w-full': true}"
                                  v-model="coach.reservePrice"/>

              <vs-button id="deleteCoachBTN"
                         class="w-full mt-3"
                         color="danger"
                         v-if="checkUserPermissions('coach.delete')"
                         @click="$refs.deleteConfirmation.showDialog()">
                {{ $t('coaches.labels.delete') }}
              </vs-button>

              <custom-dialog ref="deleteConfirmation"
                             :title="$t('coaches.confirmations.delete.title')"
                             :body="$t('coaches.confirmations.delete.body', {name: coach.name.value})"
                             @accept="deleteCoach"/>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="editCoachBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomPriceInput from "../../../../components/customInput/customPriceInput"
  import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput"
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import SelectUsers from "../../customers/select/selectUsers";
  import {deleteCoach, editCoach, getCoach, insertCoach} from "../../../../http/requests/coaches";
  import InsertUser from "../../customers/insert/insertUser";
  import CustomDialog from "../../../../components/customDialog/customDialog";

  export default {
    name: 'EditCoach',
    components: {
      CustomDialog,
      InsertUser,
      SelectUsers,
      CustomIcon,
      CustomPriceInput,
      CustomProfileImageInput,
      CustomValidateInput
    },
    props: {
      coachId: 0
    },
    data() {
      return {
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        nameRegex: this.$validator('regex.user.name'),
        gender: [
          {
            id: 1,
            label: this.$t('genderTypes.man')
          },
          {
            id: 2,
            label: this.$t('genderTypes.woman')
          }
        ],
        paymentTypes: [
          {
            label: this.$t('coaches.paymentType.daily'),
            value: 0
          },
          {
            label: this.$t('coaches.paymentType.weekly'),
            value: 1
          },
          {
            label: this.$t('coaches.paymentType.monthly'),
            value: 2
          },
        ],
        characters: [],
        coach: {
          id: 0,
          name: {
            value: '',
            isValid: true
          },
          image: {
            value: '',
            isValid: true
          },
          contractPrice: {
            value: '',
            isValid: false
          },
          reservePrice: {
            value: '',
            isValid: false
          },
          paymentType: {
            value: 2
          }
        }
      }
    },
    created() {
      this.getCoach()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },

      handleClick (id) {
        document.getElementById(id).click()
      },

      getCoach () {
        getCoach(this.coachId).then(response => {
          const coach = response.data.data
          this.coach.id = coach.user.id
          this.coach.name = {
            value: `${coach.user.name || ''} ${coach.user.family || ''}`,
            isValid: true
          }
          this.coach.contractPrice = {
            value: coach.contract_price,
            isValid: true
          }
          this.coach.reservePrice = {
            value: coach.reserve_price,
            isValid: true
          }
          this.coach.paymentType.value = coach.payment_type
        })
      },

      handleSuggestUserSelected(list) {
        let user = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            user = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2,
              avatar: data.avatar,
            }
          }
        })
        this.coach.id = user.id
        this.coach.name = user.name
        this.coach.image.value = user.avatar
        this.suggestUserPromptStatus = false
      },

      handleNewUserInserted(user) {
        this.coach.id = user.id
        this.coach.name.value = `${user.name} ${user.family}`
        this.coach.image.value = user.avatar || ''
        this.newSuggestUserPromptStatus = false
      },

      sendData() {
        if (this.coach.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.user'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.coach.contractPrice.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.contractPrice'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.coach.reservePrice.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.reservePrice'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const coach = {
          user_id: this.coach.id,
          payment_type: this.coach.paymentType.value,
          contract_price: parseInt(this.coach.contractPrice.value),
          reserve_price: parseInt(this.coach.reservePrice.value),
        }

        editCoach(this.coachId, coach).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('coaches.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })

          this.$emit('edited')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'user_id': this.$t('coaches.notifications.parseError.user'),
              'contract_price': this.$t('coaches.notifications.parseError.contractPrice'),
              'reserve_price': this.$t('coaches.notifications.parseError.reservePrice')
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('coaches.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
      deleteCoach() {
        // if (parseInt(this.coach.balance.value) > 0) {
        //   this.$vs.notify({
        //     title: this.$t('alert.error.title'),
        //     text: this.$t('coaches.notifications.hasBalance'),
        //     color: 'danger',
        //     time: 2400,
        //     icon: 'icon-alert-circle',
        //     iconPack: 'feather'
        //   })
        // } else {
          deleteCoach(this.coachId || this.$route.params.id).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('coaches.notifications.delete.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })
            this.$emit('edited')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('coaches.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        // }
      },
    }
  }
</script>

<style lang="scss">
  .edit-coach {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .custom-profile-image-input {
          .profile-image-label {
            height: 110px;
            width: 110px;
          }
        }

        .select-coach {
          position: relative;

          .suggest-modal-button, .new-suggest-modal-button {
            position: absolute;
            bottom: 0;
            right: 36px;
            top: 5px;
            width: 25px;
            height: 25px;
            padding: 0;
            border: 1px solid #00bcd4;
            border-radius: .5rem;
            color: #00bcd4;
            background: white;
            cursor: pointer;
          }

          .new-suggest-modal-button {
            right: 6px;
            border: 1px solid #28C76F;
            color: #28C76F;

            .icon-component {
              right: 2px;
            }
          }
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;
          flex-wrap: wrap;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            width: max-content;
            direction: rtl;

            label {
              direction: ltr;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.character-input {
            justify-content: space-between;

            .radio-item {
              justify-content: flex-end;
              width: 33%;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;
          flex-wrap: wrap;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            width: max-content;
            direction: rtl;

            label {
              direction: ltr;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.character-input {
            justify-content: space-between;

            .radio-item {
              justify-content: flex-end;
              width: 33%;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
