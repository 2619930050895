<template>
  <div class="coaches-list fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="coachesListTable"
                             :in-modal="roleId > 0"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCoaches()"
                             @row:clicked="showCoach"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewCoachPromptStatus"
      @close="insertNewCoachPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCoachBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('coaches.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewCoachPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-coach :in-modal="true" @inserted="reloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCoachPromptStatus"
      @close="showCoachPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="editCoachPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('coaches.profile.dynamicTitle', {name: selectedCoach.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCoachPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <coach-profile :coach-id="selectedCoach.id" @edited="reloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- edit user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editCoachPromptStatus"
      @close="editCoachPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editCoachBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('coaches.edit.dynamicTitle', {name: selectedCoach.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editCoachPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-coach :coach-id="selectedCoach.id || this.$route.params.id" @edited="reloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <button id="insertNewCoach" v-show="false" @click="insertNewCoachPromptStatus = true"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {addComma, checkUserPermissions, getAvatarUrl, getStaticFileUrl} from '../../../../assets/js/functions'
import InsertCoach from '../insert/insertCoach'
import CustomIcon from '../../../../components/customIcon/customIcon'
import CharactersList from "../../characters/list/charactersList";
import {getCoaches} from "../../../../http/requests/coaches";
import EditCoach from "../edit/editCoach";
import CoachProfile from "../profile/coachProfile";

export default {
  name: 'usersList',
  metaInfo () {
    return {
      title: this.$t('coaches.list.title')
    }
  },
  components: {
    CoachProfile,
    EditCoach,
    CharactersList,
    CustomIcon,
    // UserProfile,
    // EditUser,
    InsertCoach,
    DraggableDynamicTable
  },
  props: {
    roleId: 0
  },
  data () {
    return {
      requestSent: false,
      insertNewCoachPromptStatus: false,
      showCoachPromptStatus: false,
      editCoachPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'coachesListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'coaches.table.header.balance',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
          ],
        },
        /*{
          field: 'cost',
          i18n: 'coaches.table.header.cost',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'revenue',
          i18n: 'coaches.table.header.revenue',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'reservePrice',
          i18n: 'coaches.table.header.reservePrice',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
        },
        {
          field: 'contractPrice',
          i18n: 'coaches.table.header.contractPrice',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
        },*/
        {
          field: 'phoneNumber',
          i18n: 'coaches.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 150,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'coaches.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        // {
        //   field: 'gender',
        //   i18n: 'coaches.table.header.gender',
        //   width: 'calc(100% / 10)',
        //   minWidth: 100,
        //   align: 'center',
        //   sortable: true,
        //   filter: true,
        //   filterType: 'select',
        //   filterTypes: [
        //     {
        //       label: this.$t('genderTypes.all'),
        //       value: 0
        //     },
        //     {
        //       label: this.$t('genderTypes.man'),
        //       value: 1
        //     },
        //     {
        //       label: this.$t('genderTypes.woman'),
        //       value: 2
        //     }
        //   ]
        // },
        {
          field: 'image',
          i18n: 'coaches.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90,
          align: 'center'
        },
        {
          field: 'row',
          i18n: 'coaches.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [this.roleId > 0 ? `role=${this.roleId}` : ''],
      sorts: ['order[0]=created_at,desc'],
      selectedCoach: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewCoach',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'coach.create'
            },
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            // {
            //   id: {name: 'customersTrash'},
            //   type: 'link',
            //   icon: 'icon-trash',
            //   iconPack: 'feather',
            //   permission: 'user.delete'
            // },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getCoaches()
  },
  methods: {
    getCoaches () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.coachesListTable && this.data.length === 0) {
              this.$refs.coachesListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.coachesListTable && this.data.length > 0) {
              this.$refs.coachesListTable.loadMoreStatus = 'Loading'
            }

            getCoaches(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              const coaches = res.data.data
              coaches.forEach((coach) => {

                this.data.push({
                  row: this.data.length + 1,
                  id: coach.id,
                  registryDate: coach.created_at,
                  gender: coach.user.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                  phoneNumber: coach.user.phone_number ? coach.user.phone_number : '',
                  firstName: coach.user.name || '',
                  lastName: coach.user.family || '',
                  name: coach.user.name || coach.user.family ? `${ coach.user.name || '' } ${  coach.user.family || '' }` : this.$t('users.user.labels.unknown'),
                  reservePrice: {
                    type: 'price',
                    value: Math.abs(coach.reserve_price) || 0
                  },
                  contractPrice: {
                    type: 'price',
                    value: Math.abs(coach.contract_price) || 0
                  },
                  revenue: {
                    type: 'price',
                    value: Math.abs(coach.total_revenue) || 0
                  },
                  cost: {
                    type: 'price',
                    value: Math.abs(coach.total_cost) || 0
                  },
                  balance: {
                    type: 'price',
                    value: Math.abs(coach.balance) || 0,
                    classes: coach.balance === 0 ? 'text-primary' : coach.balance < 0 ? 'text-success' : 'text-danger'
                  },
                  creator: coach.creator ? coach.creator.role && coach.creator.role.id === 1 ? this.$t('users.user.labels.bySystem') : `${coach.creator.name || ''} ${coach.creator.family || ''}` : this.$t('users.user.labels.bySystem'),
                  image: {
                    type: 'img',
                    src: coach.user.avatar ? getAvatarUrl(coach.user.avatar) : coach.user.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
                    class: 'coach-avatar'
                  },
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              const revenue_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('revenue')
              if (revenue_index > -1) {
                this.columnsLabel[revenue_index].footer.value = addComma(res.data.extra.total.revenue, true)
              }

              const cost_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('cost')
              if (cost_index > -1) {
                this.columnsLabel[cost_index].footer.value = addComma(res.data.extra.total.cost, true)
              }

              if (this.$refs.coachesListTable) this.$refs.coachesListTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch((error) => {
                console.log(error)
                if (this.$refs.coachesListTable) this.$refs.coachesListTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showCoach (coach) {
      this.selectedCoach = coach
      this.showCoachPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'gender':
          if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`user=${  filters[key].search}`)
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
          break

        case 'contractPrice':
          if (filters[key].search !== '') filters_list.push(`contract_price=${  filters[key].search},${filters[key].type.id}`)
          break

        case 'reservePrice':
          if (filters[key].search !== '') filters_list.push(`reserve_price=${  filters[key].search},${filters[key].type.id}`)
          break

          case 'cost':
            if (filters[key].search !== '') filters_list.push(`total_cost=${filters[key].search},${filters[key].type.id}`)
            break

          case 'revenue':
            if (filters[key].search !== '') filters_list.push(`total_revenue=${filters[key].search},${filters[key].type.id}`)
            break

        case 'balance':
          if (filters[key].search !== '') filters_list.push(`balance=${  parseInt(filters[key].search) * -1},${filters[key].type.id}`)
          break
        }
      })
      if (this.roleId > 0) {
        filters_list.push(`role=${this.roleId}`)
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getCoaches()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=user,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'contractPrice':
          sorts_list.push(`order[0]=contract_price,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'reservePrice':
          sorts_list.push(`order[0]=reserve_price,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCoaches()
    },
    consoleLog () {
      // console.log(event)
    },
    reloadTable () {
      this.data = []
      this.page = 1
      this.insertNewCoachPromptStatus = false
      this.editCoachPromptStatus = false
      this.showCoachPromptStatus = false
      this.endedList = false
      this.getCoaches()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
  .coaches-list {
    .draggable-dynamic-table {
      .coach-avatar {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }
    }
  }
</style>
