<template>
  <div class="coach-transactions" :class="{'p-3': coachId > 0}">
    <draggable-dynamic-table ref="coachTransactions"
                             class="user-transactions"
                             :in-modal="coachId > 0"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @load:more="getCoachTransactions()"/>

    <button id="printCoachTransactions" v-show="false" @click="handlePrintCoachTransactions"></button>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getTimeFromServer, hideLoading, showLoading} from "../../../../assets/js/functions";
import {getCoach, getCoachTransaction, printCoachTransactions} from "@/http/requests/coaches";

export default {
  name: 'coachTransactions',
  components: {DraggableDynamicTable},
  metaInfo () {
    return {
      title: this.user ? this.$t('coaches.transactions.dynamicTitle', {name: `${this.user.name} ${this.user.family}`}) : this.$t('coaches.transactions.title')
    }
  },
  props: {
    coachId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      requestSent: false,
      options: {
        id: 'coachTransactions',
        rowKeyField: 'docNumber'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'coaches.transactions.table.header.balance',
          width: '130px',
          minWidth: 130,
          locked: true,
          // sortable: true
        },
        {
          field: 'price',
          i18n: 'coaches.transactions.table.header.price',
          width: '130px',
          minWidth: 130,
          locked: true,
          // sortable: true
          /*footer: {
            type: 'auto-sum',
            textType: 'price'
          }*/
        },
        {
          field: 'documentation',
          i18n: 'coaches.transactions.table.header.documentation',
          width: '120px',
          minWidth: 120,
          align: 'center',
          locked: true,
          // sortable: true
        },
        {
          field: 'date',
          i18n: 'coaches.transactions.table.header.date',
          align: 'center',
          width: '70px',
          minWidth: 70,
          locked: true,
          // sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'coaches.transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          // sortable: true
          /*footer: {
            type: 'auto-counter',
          }*/
        }
      ],
      user: null,
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'coach', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.coachId === 0) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)
    }

    getCoach(this.coachId || this.$route.params.id).then(response => {
      this.user = response.data.data
      if (!this.coachId) this.$store.dispatch('setPageTitle', this.$t('coaches.transactions.dynamicTitle', {name: `${this.user.name} ${this.user.family}`}))
    })

    this.getCoachTransactions()
  },
  methods: {
    getCoachTransactions () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.coachTransactions && this.data.length === 0) {
              this.$refs.coachTransactions.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.coachTransactions && this.data.length > 0) {
              this.$refs.coachTransactions.loadMoreStatus = 'Loading'
            }

            getCoachTransaction(this.coachId || this.$route.params.id, this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0

              const coachTransactions = response.data.data
              coachTransactions.forEach((transaction) => {
                this.data.push({
                  docNumber: transaction.document.id,
                  date: transaction.created_at,
                  documentation: transaction.document.description,
                  price: {
                    type: transaction.price !== 0 ? 'price' : 'number',
                    classes: transaction.price > 0 ? 'text-danger' : transaction.price === 0 ? 'text-primary' : 'text-success',
                    value: transaction.price ? Math.abs(transaction.price) : 0
                  },
                  balance: {
                    type: transaction.balance !== 0 ? 'price' : 'number',
                    classes: transaction.balance > 0 ? 'text-danger' : transaction.balance === 0 ? 'text-primary' : 'text-success',
                    value: transaction.balance ? Math.abs(transaction.balance)  : 0
                  },
                  route: transaction.document.reference_type !== '' ? this.getDocumentReferenceRoute(transaction, transaction.document.reference) : {}
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('docNumber')

                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.coachTransactions) this.$refs.coachTransactions.loadMoreStatus = ''
              this.requestSent = false
            }).catch((error) => {
              if (this.$refs.coachTransactions) this.$refs.coachTransactions.loadMoreStatus = 'Danger'
              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 500)
      }
    },
    getDocumentReferenceRoute (transaction, reference) {
      switch (transaction.document.reference_type) {
      case 'Invoice':
        const mapper = {
          '1': 'saleInvoice',
          '2': 'purchaseInvoice',
          '3': 'returnSaleInvoice',
          '4': 'returnPurchaseInvoice',
          '5': 'treasuryReceiveDocument'
        }
        let type = 1
        switch (transaction.document.type) {
          case this.$t('coaches.transactions.types.sale'):
            type = 1
            break

          case this.$t('coaches.transactions.types.purchase'):
            type = 2
            break

          case this.$t('coaches.transactions.types.receive'):
            type = 5
            break
        }
        return {name: mapper[type], params: {id: reference.id}, target: '_blank'}

      case 'OpeningInventory':
        return {name: 'openingBalance'}

      default:
        return {}
      }
    },

    handlePrintCoachTransactions () {
      showLoading()

      printCoachTransactions(this.coachId || this.$route.params.id, this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `coachTransactionList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('coaches.transactions.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.coach-transactions {
  height: 100%;
}
</style>
