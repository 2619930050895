<template>
  <vx-card class="user-profile-page"
           :class="{'main-box top-zero-radius md:w-1/2': !coachId, 'no-box-shadow': coachId > 0}"
           v-if="user && Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :actionsList="actionsList"
                      @showCoachTransactions="showCoachTransactionsPromptStatus = true"
                      @coachReceive="showCoachReceivePromptStatus = true"
                      @coachClasses="showCoachClassesListPromptStatus = true"/>


    <!-- show user transactions prompt -->
    <vs-prompt
        class="very-big-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="showCoachTransactionsPromptStatus"
        @close="showCoachTransactionsPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">
                          <custom-icon icon="EDIT" color="warning"/>
                        </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.transactions.dynamicTitle', {name: `${user.general.gender === 1 ? $t('users.genderTypes.dynamicMan') : $t('users.genderTypes.woman')} ${user.general.name.value} ${user.general.lastName.value}`}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
            <!--            <div class="mx-2" @click="handleClick('printUserTransactions')">-->
            <!--              <custom-icon icon="PRINT"/>-->
            <!--            </div>-->
            <div @click="showCoachTransactionsPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <coach-transactions :coach-id="coachId || $route.params.id"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!--  show coach received prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCoachReceivePromptStatus"
      @close="showCoachReceivePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('receivePaymentGatewayBalanceBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('coaches.receive.dynamicTitle', {name: getUserProfile.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCoachReceivePromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <received-coach-balance :coach-id="coachId" @received="handleCoachBalanceReceived"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!--  show coach classes list prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCoachClassesListPromptStatus"
      @close="showCoachClassesListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('coaches.classes.dynamicTitle', {name: getUserProfile.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCoachClassesListPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <coach-classes-list :coach-id="coachId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
import {getUser} from '@/http/requests/users/users'
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
import {addComma, checkUserPermissions} from '@/assets/js/functions'
import CustomIcon from '../../../../components/customIcon/customIcon'
import {getAvatarUrl, getStaticFileUrl} from "../../../../assets/js/functions";
import InsertFastReceive from "../../treasury/receive/insert/insertFastReceive";
import {getCoach} from "../../../../http/requests/coaches";
import ReceivedCoachBalance from "../receive/receivedCoachBalance";
import CoachClassesList from "../classes/coachClassesList";
import CoachTransactions from "@/views/admin/coaches/transactions/coachTransactions.vue";

export default {
  name: 'CoachProfile',
  components: {CoachTransactions, CoachClassesList, ReceivedCoachBalance, InsertFastReceive, CustomIcon, ProfileMainBox},
  props: {
    coachId: 0
  },
  metaInfo () {
    return {
      title: this.user ? this.$t('coaches.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}) : this.$t('coaches.profile.title')
    }
  },
  data () {
    return {
      showCoachTransactionsPromptStatus: false,
      showCoachReceivePromptStatus: false,
      showCoachClassesListPromptStatus: false,
      actions: {
        toolbar: [
          {
            id: {name: 'editUser', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            color: 'warning'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'users'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      },
      user: null,
      paymentTypes: {
        0: this.$t('coaches.paymentType.daily'),
        1: this.$t('coaches.paymentType.weekly'),
        2: this.$t('coaches.paymentType.monthly'),
      },
      actionsList: [
        {
          event: 'showCoachTransactions',
          params: {id: this.coachId || this.$route.params.id},
          header: this.$t('coaches.profile.actions.transactions'),
          preview: {
            value: addComma(0) + this.$t('currency'),
            color: 'success'
          },
          i18n: '',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'coachReceive',
          i18n: 'coaches.profile.actions.receive',
          icon: 'icon-dollar-sign',
          iconPack: 'feather'
        },
        {
          event: 'coachClasses',
          i18n: 'coaches.profile.actions.classes',
          icon: 'CALENDER',
          colorCode: '#000',
          iconPack: 'useral',
        },
      ]
    }
  },
  created () {
    //check permissions
    if (!checkUserPermissions('user.update')) {
      delete this.actions.toolbar
    }

    if (!this.coachId) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getUser()
  },
  computed: {
    getUserProfile () {
      const user = {
        avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
        name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
        general: this.user.general,
        asiderAvatar: [
          // {
          //   content: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
          //   preContent: this.user.general.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('genderTypes.woman') : ''
          // },
          {
            content: this.user.paymentType,
            preContent: this.$t('coaches.profile.asiderAvatar.paymentType')
          },
          {
            content: `${addComma(this.user.contractPrice) || '0'} ${this.$t('currency')}`,
            preContent: this.$t('coaches.profile.asiderAvatar.contractPrice')
          },
          {
            content: `${addComma(this.user.reservePrice) || '0'} ${this.$t('currency')}`,
            preContent: this.$t('coaches.profile.asiderAvatar.reservePrice')
          },
          // {
          //   content: this.user.general.character ?  this.user.general.character.name : '',
          //   preContent: this.$t('users.user.profile.asiderAvatar.character')
          // },
        ],
        details: [],
      }

      return user
    },
    getUserTransactions () {
      return {
        value: this.user.financial.balance || 0
      }
    }
  },
  methods: {
    getUser () {

      getCoach(this.coachId || this.$route.params.id).then(res => {
        const coach = res.data.data

        this.user = {
          general: {
            name: {
              value: coach.user.name,
              isInvalid: ''
            },
            lastName: {
              value: coach.user.family,
              isInvalid: ''
            },
            gender: coach.user.gender || '',
            phoneNumber: {
              value: coach.user.phone_number,
              isInvalid: ''
            }
          },
          id: coach.user.id,
          avatar: coach.user.avatar || '',
          contractPrice: coach.contract_price,
          reservePrice: coach.reserve_price,
          paymentType: this.paymentTypes[coach.payment_type],
          financial: {
            balance: coach.balance ? coach.balance : 0
          }
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('showCoachTransactions')
        let actionsList = JSON.parse(JSON.stringify(this.actionsList))
        if (balance_index > -1) {
          actionsList[balance_index].preview = {
            value: `${addComma(Math.abs(this.user.financial.balance)) || 0} ${this.$t('currency')}`,
            color: this.user.financial.balance < 0 ? 'success' : this.user.financial.balance > 0 ? 'danger' : ''
          }
        }

        const maxDebt_index = this.actionsList.map(e => e.event).indexOf('setUserMaxDebt')
        if (maxDebt_index > -1) {
          actionsList[maxDebt_index].preview.value = `${addComma(Math.abs(this.user.financial.maxDebt)) || 0} ${this.$t('currency')}`
        }

        this.actionsList = []
        setTimeout(() => {
          this.actionsList = actionsList
        }, 50)


        if (!this.user.access) {
          this.actionsList.splice(2, 1)
        }

        if (!this.coachId) {
          this.$store.dispatch('setPageTitle', this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
        }
      })
    },
    handleCoachBalanceReceived () {
      this.showCoachReceivePromptStatus = false
      this.$emit('edited')
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.user-profile-page {

  .vx-card__body {
    padding: 0 !important;

    .profile-main-box2 {
      padding: 0;
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
